import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  listviewInit: ref(1),
  listviewData: ref([]),
  positionListview: {},
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: 'lastChange',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ID encomenda, nome ou contribuinte do cliente'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: 'numero_doc',
      txt: i18n.t('Ordenar por ID'),
    },
    {
      name: 'data_registo',
      txt: i18n.t('Ordenar por data registo'),
    },
  ],
  filterSelectedTotalFields: ref(0),

  // Fields to filter
  filterDataSalesMan: ref({}),
  filterDataStatusOrder: ref({}),
  filterDataLoad: ref(false),

  filterSelectedSalesMan: ref({ email: '', name: i18n.t('Todos') }),
  filterSelectStatusOrder: ref(['all']),
  filterSelectDateStart: ref(''),
  filterSelectDateEnd: ref(''),

  // Others
  totalSales: ref(0),

})

export default defaultState
