<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true, 'sws-not-button-create': false }"
        >
          <div
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              :disabled="$store.getters['sales_order/loadingListview']"
              @click="createNew($event);$emit('close-left-sidebar');"
            >
              {{ $t('Nova') }}
            </b-button>
          </div>

          <vue-perfect-scrollbar
            v-if="filterData.load.value===true"
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >
            <!-- Estado -->
            <h6
              v-if="(Object.keys(filterData.statusOrder.value).length > 0)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Estado') }}
            </h6>
            <b-list-group
              v-if="(Object.keys(filterData.statusOrder.value).length > 0)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.statusOrder.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilter({'filterSelectStatusOrder': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="indexStatus in Object.keys(filterData.statusOrder.value)"
                :key="indexStatus"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.statusOrder.value.includes(indexStatus)) ? true : false)"
                @click.stop.prevent="listviewFilter({'filterSelectStatusOrder': indexStatus })"
              >
                <span class="align-text-bottom line-height-1">{{ filterData.statusOrder.value[indexStatus] }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Intervalo de datas -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Intervalo de datas') }}
            </h6>
            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item>
                <b-input-group>
                  <cleave
                    v-model="filterSelected.dateStart.value"
                    class="form-control"
                    inputmode="numeric"
                    :raw="false"
                    :options="maskDate"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    readonly
                    @input="selectFilterDateStart($event)"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      button-variant="outline-theme h-auto"
                      placeholder="yyyy-mm-dd"
                      autocomplete="off"
                      show-decade-nav
                      hide-header
                      locale="pt"
                      label-help=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      button-only
                      right
                      @input="selectFilterDateStart($event)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <h6
                  class="section-label text-center mt-1 mb-1"
                >
                  {{ $t('a') }}
                </h6>
                <b-input-group>
                  <cleave
                    v-model="filterSelected.dateEnd.value"
                    class="form-control"
                    inputmode="numeric"
                    :raw="false"
                    :options="maskDate"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    readonly
                    @input="selectFilterDateEnd($event)"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSelected.dateEnd.value"
                      :min="filterSelected.dateStart.value"
                      button-variant="outline-theme h-auto"
                      placeholder="yyyy-mm-dd"
                      autocomplete="off"
                      show-decade-nav
                      hide-header
                      locale="pt"
                      label-help=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      button-only
                      right
                      @input="selectFilterDateEnd($event)"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-list-group-item>
            </b-list-group>

            <!-- Vendedor -->
            <template v-if="$can('manager', 'sales')">
              <h6
                v-if="(filterData.salesMan.value.length > 0)"
                class="section-label mt-3 mb-1 px-2"
              >
                {{ $t('Vendedor') }}
              </h6>
              <b-list-group
                v-if="(filterData.salesMan.value.length > 0)"
                class="list-group-messages"
              >
                <b-list-group-item>
                  <v-select
                    v-model="filterSelected.salesMan.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterData.salesMan.value"
                    label="name"
                    item-text="name"
                    item-value="email"
                    class="limitHeightUserResponsable"
                    @input="listviewFilterSalesMan(filterSelected.salesMan.value)"
                  >
                    <template #option="{ name }">
                      <span> {{ name }}</span>
                    </template>
                    <div slot="no-options">
                      {{ $t('Nenhum resultado') }}
                    </div>
                  </v-select>
                </b-list-group-item>
              </b-list-group>
            </template>

            <!-- Botao limpar -->
            <div
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                :disabled="$store.getters['sales_order/loadingListview']"
                @click="resetListviewFilter"
              >
                {{ $t('Limpar filtro') }}
              </b-button>
            </div>

            <!-- Extra space -->
            <h6
              class="section-label mt-1 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BListGroup, BListGroupItem, BInputGroup, BFormDatepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import Cleave from 'vue-cleave-component'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    VuePerfectScrollbar,
    vSelect,
    Cleave,
  },
  mixins: [sizeIconsAction],
  props: {
    createNew: {
      type: Function,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maskDate: {
        date: true,
        delimiter: '-',
        datePattern: ['Y', 'm', 'd'],
      },
    }
  },
  computed: {
    ...mapGetters('sales_order', ['filterData', 'filterSelected']),
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
  methods: {
    ...mapActions('sales_order', ['selectFilterDateStart', 'selectFilterDateEnd']),
    listviewFilterSalesMan(oSalesManSelected) {
      this.$store.dispatch('sales_order/listviewFilterSalesMan', oSalesManSelected).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilter(params) {
      this.$store.dispatch('sales_order/listviewFilter', params).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilter() {
      this.$store.dispatch('sales_order/resetListviewFilter').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list {
  height: calc(100%);
}

.limitHeightUserResponsable ::v-deep {
  .vs__dropdown-menu {
    max-height: 175px !important;
  }
}
</style>
