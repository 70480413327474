<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="renderIdOrder(row)"
                  class=""
                >
                  {{ renderIdOrder(row) }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="renderNameClient(row)"
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderNameClient(row)"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ renderNameClient(row) }}
                </span>
                <div
                  v-if="renderNameClient(row)"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Nome cliente') }}
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="renderNifClient(row)"
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderNifClient(row)"
                >
                  {{ renderNifClient(row) }}
                </span>
                <div
                  v-if="renderNifClient(row)"
                  class="mail-message"
                >
                  <p
                    v-if="renderNifClient(row)"
                    class="mb-0"
                  >
                    {{ $t('Nº contribuinte') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderDateCreate(row)"
                >
                  {{ renderDateCreate(row) }}
                </span>
                <br>
                <div
                  v-if="renderDateCreate(row)"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Data de registo') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderStatusOrder(row)"
                >
                  <b-avatar
                    size="32"
                    :variant="`light-${renderStyleStatusOrder(row).variant}`"
                    class="mr-1"
                  >
                    <feather-icon
                      :icon="renderStyleStatusOrder(row).icon"
                    />
                  </b-avatar>

                  <b-badge
                    pill
                    :variant="`light-${renderStyleStatusOrder(row).variant}`"
                  >
                    {{ renderStatusOrder(row) }}
                  </b-badge>
                </span>
                <br>
                <div
                  v-if="renderStatusOrder(row)"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Estado da encomenda') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderTotalPayOrder(row)"
                >
                  {{ renderTotalPayOrder(row) }}
                </span>
                <br>
                <div
                  v-if="renderTotalPayOrder(row)"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Total da encomenda') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3"
                class="mt-1"
              >
                <span
                  v-if="renderOrderCreatedByApp(row)"
                >
                  {{ renderOrderCreatedByApp(row) }}
                </span>
                <br>
                <div
                  v-if="renderOrderCreatedByApp(row)"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Criada em') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import formatMoney from 'accounting-js/lib/formatMoney'
import {
  BMedia, BMediaBody, BContainer, BRow, BCol, BBadge, BAvatar,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaBody,
    BContainer,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  computed: {
    ...mapGetters('sales_order', ['listviewData']),
  },
  methods: {
    renderIdOrder(row) {
      let sTxtDoc = ''
      if ((row?.type_doc_txt !== undefined) && (row.type_doc_txt !== '')) {
        sTxtDoc += row.type_doc_txt
      }

      if ((row?.numero_doc !== undefined) && (row.type_doc_txt !== '')) {
        sTxtDoc += ((sTxtDoc) ? ' ' : '') + row.numero_doc
      }

      return sTxtDoc || ''
    },
    renderNameClient(row) {
      return row?.nome_cliente || ''
    },
    renderNifClient(row) {
      return row?.nif_cliente || ''
    },
    renderDateCreate(row) {
      return row?.data_registo || ''
    },
    renderStatusOrder(row) {
      return row?.estado || ''
    },
    renderTotalPayOrder(row) {
      return formatMoney(row?.total_encomenda || 0, {
        symbol: '€', format: '%v %s', precision: 2, thousand: ' ', decimal: ',',
      })
    },
    renderOrderCreatedByApp(row) {
      if ((row?.createByMyApp !== undefined) && (row.createByMyApp === '1')) {
        return this.$t('Aplicação MyApp')
      }

      return ''
    },
    renderStyleStatusOrder(row) {
      let style = {
        variant: '',
        icon: '',
      }
      if ((row?.estado_tag !== undefined) && (row.estado_tag !== '')) {
        if (row.estado_tag === 'created') {
          style = {
            variant: 'primary',
            icon: 'FileTextIcon',
          }
        } else if (row.estado_tag === 'waitConfirm') {
          style = {
            variant: 'warning',
            icon: 'LoaderIcon',
          }
        } else if (row.estado_tag === 'processing') {
          style = {
            variant: 'info',
            icon: 'ArchiveIcon',
          }
        } else if (row.estado_tag === 'close') {
          style = {
            variant: 'success',
            icon: 'TruckIcon',
          }
        } else if (row.estado_tag === 'cancel') {
          style = {
            variant: 'danger',
            icon: 'XCircleIcon',
          }
        }
      }

      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.txt-grey-link {
  color: $text-muted;
}
</style>
